<div class="rsvp-form">
	<p class="date">October 20</p>
	<p class="time">5-8pm</p>
	<p class="location">at the Common Roots Homestead</p>
	<p class="body">
		Join us for a night of conversation and tasty food at Common Root's rustic homestead.
	</p>

	<p class="dinein">Dine in and take out available!</p>

	<a href="/menu" class="menu">menu</a>
	<a href="/rsvp" class="btn">RSVP</a>
	<p>RSVP requested</p>
</div>

<style>
	.rsvp-form {
		border: var(--primary) 5px solid;
		padding: 1em;
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.menu {
		margin-bottom: 1em;
	}
	.btn {
		display: block;
		margin-top: auto;
		margin-bottom: 0.5em;
		width: 100%;
	}
	.date {
		font-size: 2.5rem;
		font-family: 'roc-grotesk-wide', sans-serif;
		font-weight: 700;
		color: var(--primary);
	}
	.time {
		font-size: 2rem;
		font-family: 'roc-grotesk-wide', sans-serif;
		font-weight: 700;
		color: var(--secondary);
	}
	.location {
		font-weight: bold;
		margin: 0.5em 0 1em 0;
	}
	.dinein {
		margin: 0.75em 0;
	}
	.body {
		text-align: justify;
	}
</style>
