<script>
	import cooking from '$lib/images/webp/micheal_marit_cooking.webp';
	import students from '$lib/images/webp/jordanian_students.webp';
	import RSVP from '$lib/components/Rsvp.svelte';
	import EmailSignup from '$lib/components/EmailSignup.svelte';
	let date = 'October 20th, 2024';
</script>

<svelte:head>
	<title>Jordanian Food for Funds Pop Up Dinner</title>
	<meta
		name="description"
		content={`Jordanian Food for Funds is a pop up dinner on ${date} from 5-8pm to raise money for Syrian refugee education in Jordan.`}
	/>
</svelte:head>
<main>
	<header>
		<div class="content">
			<h1>
				<span>Enjoy a meal,</span> <br />help Syrian refugee children establish roots in Jordan
			</h1>
			<h2>{date} at the Common Roots Homestead <span>– RSVP Requested</span></h2>
			<p class="diningopt">
				<span>dine-in</span> and <span>take-out</span> available! – <a href="/menu">menu</a>
			</p>
		</div>
		<a href="/rsvp" class="btn">RSVP</a>
	</header>
	<div class="image-row">
		<enhanced:img
			src="$lib/images/webp/micheal_marit_cooking.webp"
			alt="Two roots for refugees team members rolling pita bread in a kitchen."
			class="chefs"
		/>
		<enhanced:img
			src="$lib/images/webp/jordanian_students.webp"
			alt="Three students at the Azraq Center in Jordan."
		/>
		<div class="rsvp-cont"><RSVP /></div>
	</div>

	<div id="about">
		<h2 class="secondary_heading">About the Project</h2>
		<div class="paragraph">
			<p class="text-block">
				Last March, our team of five had the opportunity to visit Jordan as a part of an
				undergraduate course at Champlain College. We loved watching sunrises in the Wadi Rum,
				visiting mosques in Amman, and exploring the ancient ruins scattered across the country, but
				the most impactful part of our trip was visiting the Azraq Education Center.
			</p>
			<p class="text-block">
				Based in Azraq, a tiny town on the outskirts of Jordan, the center provides life changing
				education and services to Syrian refugee children who have been forced to flee their homes
				due to conflict. From the moment we stepped foot on their beautiful campus, the students
				flocked around us, wanting to show their state of the art classrooms and shaded play
				structures. We spent much longer than anticipated that day laughing with the kids and
				listening to the center’s staff enthusiastically describe the various programs and services
				they are able to provide for their community.
			</p>
			<p class="text-block">
				When we returned to Burlington, we wanted to support the centers mission. Along with other
				initiatives, we have started hosting a bi-annual popup dinner to raise funds to donate.
			</p>
		</div>
		<a href="/about">learn more about our mission</a>
	</div>

	<div class="img-grid">
		<enhanced:img
			loading="lazy"
			class="img"
			src="$lib/images/webp/hero.webp"
			alt="Champlain College students and Azraq Center students chatting and standing on the Azraq Center campus."
		/>
		<enhanced:img
			loading="lazy"
			class="img"
			src="$lib/images/webp/slide.webp"
			alt="Roots for Refugees volunteer standing on a slade with Azraq Center students."
		/>

		<enhanced:img
			loading="lazy"
			class="img"
			src="$lib/images/webp/farm.webp"
			alt="Azraq Center faculty member holding fresh produce he grew for the kids."
		/>
		<enhanced:img
			loading="lazy"
			class="img"
			src="$lib/images/webp/playingbasketball.webp"
			alt="Azraq Center students playing basketball."
		/>
	</div>
	<div class="section">
		<EmailSignup />
	</div>
</main>

<style>
	header h1 {
		line-height: 1.25;
		margin-bottom: 1em;
	}
	header h2 {
		color: var(--primary);
		margin-bottom: 0 em;
	}
	header h2 span {
		color: var(--secondary);
	}
	header .content {
		margin-top: 2vh;
		margin-bottom: auto;
	}
	.diningopt {
		font-weight: bold;
		margin-top: 5em;
	}
	header h1 span {
		font-size: 4rem;
	}
	header .btn {
		margin-right: auto;
		margin-bottom: 5vh;
		margin-top: 2em;
	}
	header {
		min-height: 55vh;
		display: flex;
		justify-content: top;
		flex-direction: column;
		margin-bottom: 2em;
	}

	.image-row {
		display: grid;
		grid-template-columns: 4fr 2fr 350px;
		grid-gap: 1em;
		width: 100%;
		grid-template-rows: auto;
		overflow: hidden;
	}
	#about {
		margin-top: 4em;
	}
	@media only screen and (max-width: 1500px) {
		.image-row {
			grid-template-columns: 1fr 2fr;
		}
		.image-row picture:first-child {
			grid-column: 1/3;
		}
		.home-content-container {
			display: grid;
			grid-template-columns: auto;
			grid-gap: 2em;
		}
	}
	@media only screen and (max-width: 780px) {
		.image-row {
			grid-template-columns: 1.5fr 2fr;
		}
	}
	@media only screen and (max-width: 700px) {
		.image-row {
			grid-template-columns: 4fr 2fr;
			grid-template-rows: auto auto;
			grid-column-gap: 0.5em;
		}
		.image-row picture:first-child {
			grid-column: 1/2;
		}
		.rsvp-cont {
			grid-column: 1/3;
		}
		.img-grid {
			grid-gap: 0.5em !important;
		}
		header h1 span {
			font-size: 3rem;
		}
		header h1 {
			font-size: 1.5em;
			margin-bottom: 1em;
		}
		header h2 {
			font-size: 1em;
			margin-bottom: 0 em;
		}
	}
	@media only screen and (max-width: 450px) {
		header h1 span {
			font-size: 3rem;
			line-height: 1.5;
		}
		header h1 {
			font-size: 1.25rem;
			margin-bottom: 1em;
		}
		header h2 {
			font-size: 1rem;
			margin-bottom: 0 em;
		}
	}
	.rsvp-cont {
		height: 100%;
		display: flex;
		width: 100%;
	}
	.image-row img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.img-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		overflow: hidden;
		width: 100%;
		grid-gap: 1em;

		margin: 4em 0;
	}
	.img-grid img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.img-grid picture:first-child {
		grid-column: span 2;
	}
	.img-grid picture:last-child {
		grid-column: span 2;
	}
</style>
